
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import type { ContactList } from '@/definitions/contacts/lists/types'
import ContactsListsModalFilter from '@/components/pages/contacts/lists/ContactsListsModalFilter.vue'
import ContactsSegmentsTable from '@/components/pages/contacts/segments/ContactsSegmentsTable.vue'
import { formatDate } from '@/services/dateTimeService'
import type { ContactSegment } from '@/definitions/contacts/segments/types'

export default defineComponent({
  name: 'ContactsTableModal',
  components: {
    ContactsSegmentsTable,
    ContactsListsModalFilter,
    TmModal,
  },
  props: {
    title: {
      type: String,
      default: 'Contacts',
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<ContactList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Segment name', value: 'segment-name', width: '23%' },
      { text: 'Contacts', value: 'contacts', width: '10%' },
      { text: 'Tags', value: 'tags', hideSortable: true },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val), width: '12%' },
    ])
    const tableItems = ref<ContactSegment[]>(getTableData('contactsSegments'))
    const filteredTableItems = computed<ContactSegment[]>(() => {
      return tableItems.value.filter((item) => item.segmentName.toLowerCase().includes(search.value.toLowerCase()))
    })
    const addContacts = (func: (num: number) => void, func2: () => void) => {
      func(selected.value.length)
      func2()
    }

    return {
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      addContacts,
    }
  },
})
