import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_lists_modal_filter = _resolveComponent("contacts-lists-modal-filter")!
  const _component_contacts_segments_table = _resolveComponent("contacts-segments-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "segmentsTableModal",
    "modal-title": _ctx.title,
    size: "xLarge",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(({ hideModal }) => [
      _createVNode(_component_contacts_lists_modal_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        selected: _ctx.selected,
        "add-type": "segment",
        "search-placeholder": "Search segments",
        onClose: ($event: any) => (_ctx.addContacts(_ctx.onSubmit, hideModal))
      }, null, 8, ["search", "selected", "onClose"]),
      _createVNode(_component_contacts_segments_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        "item-key": "id",
        "items-name": "segments",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    _: 1
  }, 8, ["modal-title"]))
}